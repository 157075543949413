<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else>
      <div v-if="grades.length">

        <h4 class="mt-4 mb-3 text-center">Журнал учета успеваемости (академ разница)</h4>
        <div class="mb-5 text-center">{{grades[0].name}}</div>

        <div v-if="!isPractice">
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">№</th>
              <th scope="col">Баркод</th>
              <th scope="col">ФИО</th>
              <th scope="col">ВСК 1</th>
              <th scope="col">ВСК 2</th>
              <th scope="col">Экзамен</th>
              <th scope="col">Общий балл</th>
              <th scope="col">GPA</th>
              <th scope="col">Оценка</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(student, studentIndex) in grades" :key="studentIndex">
              <th scope="row">{{studentIndex+1}}</th>
              <td>{{student?.barcode}}</td>
              <td>{{student?.last_name}} {{student?.first_name}} {{student?.middle_name}}</td>
              <td>{{student.vsk1}}</td>
              <td>{{student.vsk2}}</td>
              <td>{{student.exam}}</td>
              <td>{{student.score}}</td>
              <td>{{student.num_gpa}}</td>
              <td>{{student.letter_equiv}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-else>

          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">№</th>
              <th scope="col">Баркод</th>
              <th scope="col">ФИО</th>
              <th scope="col">GPA</th>
              <th scope="col">Оценка</th>
              <th scope="col">Общий балл</th>
              <th scope="col">Действие</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(student, studentIndex) in grades" :key="studentIndex">
              <th scope="row">{{studentIndex+1}}</th>
              <td>{{student?.barcode}}</td>
              <td>{{student?.last_name}} {{student?.first_name}} {{student?.middle_name}}</td>
              <td>{{student.num_gpa}}</td>
              <td>{{student.letter_equiv}}</td>
              <td>
                <div v-if="+student.close_status===1">
                  {{student.score}}
                </div>
                <div v-else>
                  <input class="form-control" type="number" max="100" min="0" placeholder="Итоговый"
                         v-model="student.score">
                </div>
              </td>
              <td>
                <div v-if="+student.close_status===1">
                  Оценка поставлена
                </div>
                <div v-else>
                  <Button icon="pi pi-save" class="p-button-rounded" :loading="loadingSaveGrade"
                          @click="saveGrade(student.difference_ratings_id)"/>
                </div>
              </td>
            </tr>
            </tbody>
          </table>

        </div>

      </div>
      <h5 v-else class="text-center mt-4">Курс не найден</h5>

    </div>
  </div>

</template>

<script>

import {mapActions, mapState} from "vuex"

export default {
  name: "TeacherDifferenceGrades",
  data() {
    return {
      //mdl_course_id: +this.$route.query.mdl_course_id || null,
      mdl_course_id: +this.$route.query.mdl_course_id || null,
      loading: true,
      grades: [],
      loadingSaveGrade: false
    }
  },
  computed: {
    ...mapState('teacherDifference', ['teacherDifference_form']),
    isPractice() {
      return this.grades.length && (+this.grades[0].is_practice) === 1
    },
  },

  methods: {
    ...mapActions('teacherDifference', ['GET_DIFFERENCE_GRADES_BY_DIFFERENCE_COURSE', 'POST_TOTAL_GRADE']),
    async saveGrade(difference_ratings_id) {
      this.loadingSaveGrade = true
      const gradeIndex = this.grades.findIndex(i => i.difference_ratings_id === difference_ratings_id)
      if (gradeIndex >= 0) {
        const data = await this.POST_TOTAL_GRADE({
          id: this.grades[gradeIndex].difference_ratings_id,
          score: this.grades[gradeIndex].score
        })
        if (data && data?.id) {
          await this.getDifferenceGrades()
          this.$message({title: 'Оценка успешно поставлена'})
        }
      }
      this.loadingSaveGrade = false
    },
    async getDifferenceGrades() {
      if (this.mdl_course_id) {
        await this.GET_DIFFERENCE_GRADES_BY_DIFFERENCE_COURSE(this.mdl_course_id)
        this.grades = [...this.teacherDifference_form.grades]
      }
    }
  },
  async mounted() {
    await this.getDifferenceGrades()
    this.loading = false
  }

}
</script>

<style scoped>

</style>
